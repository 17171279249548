import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TableHeader.module.scss';

const TableHeader = ({ text }) => {
    return <div className={styles.container}>
        <div className={styles.text}>{text}</div>
    </div>
};

TableHeader.propTypes = {
    text: PropTypes.string.isRequired
};

export default TableHeader;