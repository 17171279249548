
import ReactDOM from 'react-dom/client';;
import App from './App';

import WebFont from 'webfontloader';
import 'normalize.css/normalize.css';
import './styles/dx.common.scss';
import './styles/dx.light.scss';

// load OTC fonts
WebFont.load({
  google: {
    families: [
      'Nunito Sans:200,200i,400,400i,600,600i,700,700i,800,800i900,900i',
      'Source Serif Pro:400,700'
    ]
  }
});

let root;

if (window.location.port === '3006') {
  const root = ReactDOM.createRoot(
    document.getElementById('eqv-root')
  );

  root.render(<App />);
}

window.renderEQV = (containerId) => {
  const container = document.getElementById(containerId);
  root = ReactDOM.createRoot(container);

  root.render(<App />);
};

window.unmountEQV = containerId => {
  root.unmount();
};
