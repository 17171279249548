import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import { createSymbolListQueryParams } from '../../utils/helper';
import { useEqv } from '../../context/Data.context';
import { getIndividualOverview } from '../../api/overview';
import { DataIndividual } from '../DataIndividual';
import { Loading } from '../Loading';
import { Montage } from '../Montage';
import { Popup } from 'devextreme-react';
import styles from './IndividualOverview.module.scss';

const IndividualOverview = ({ show, info: { notional, executionPrice, price }, orderType, onClose }) => {
    const [dataState, dispatchData] = useEqv();
    const [data, setData] = useState({ data: [], isLoaded: false });
    const filters = dataState.appliedFilters;
    let titleType;

    switch(orderType) {
        case '':
            titleType = 'Overall EQ'
            break;
        case 'M':
            titleType = 'Market Order Type EQ'
            break;
        case 'L':
            titleType = 'Marketable Limit Order Type EQ'
            break;
        default:
            titleType = ''
    };

    const title = `${titleType} Notional ${notional} | Exec Price ${executionPrice} = ${price}`
    useEffect(() => {
        const params = {
            ...createSymbolListQueryParams(filters),
            orderType,
            bucketNotional: notional,
            bucketPrice: executionPrice
        };

        getIndividualOverview(params)
            .then(data => setData({
                records: data,
                isLoaded: true
            }))
            .catch(err => setData({
                error: err && err.message || 'Something went wrong, try again.'
            }));
    }, []);

    const handleGoBack = () => {
        dispatchData({
          type: 'REMOVE_MONTAGE'
        });
    };

    const renderTitle = () => {
        return <div className={styles.titleContainer}>
            <div className={styles.left}>
                {dataState.popupTitle && <FaChevronLeft className={styles.backBtn} onClick={handleGoBack} />}
                {dataState.popupTitle || title}
            </div>
            <FaTimes className={styles.closeBtn} onClick={onClose} />
        </div>
    };

    return <label>
        <Popup
            visible={show}
            closeOnOutsideClick
            titleComponent={renderTitle}
            dragEnabled={false}
            onHiding={onClose} >
                <Loading
                    type='table'
                    loaded={data.isLoaded}
                    error={(data.error && data.error !== 'canceled') && data.error}>
                    <DataIndividual data={data.records} hideGrid={dataState.popupTitle} />
                    {dataState.popupTitle && <Montage />}
                </Loading>
        </Popup>
  </label>;
};

IndividualOverview.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderType: PropTypes.string
};

export default IndividualOverview;
