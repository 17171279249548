import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEqv } from '../../context/Data.context';
import DataGrid, { Column, Paging, Scrolling } from 'devextreme-react/data-grid';
import { formatDateTime, formatApiDate, formatDateTimeWithMilliseconds } from '../../utils/helper';
import styles from './DataRoutesIndividual.module.scss';

const DataRoutesIndividual = ({ data, hideGrid }) => {
    const [dataState, dispatchData, handleLoadData, loadMontage] = useEqv();
    const columns = [
        {
            caption: 'Contra',
            dataField: 'contra',
            format: null
        },
        {
            caption: 'Symbol',
            dataField: 'symbol',
            format: null,
            cellRender: (item) => <a href={`https://www.otcmarkets.com/stock/${item.value}/overview`} target='_blank' rel='noopener noreferrer'>{item.value}</a>
        },
        {
            caption: 'Order ID',
            dataField: 'orderId',
            format: null
        },
        {
            caption: 'Order Time',
            dataField: 'orderTime',
            // format: 'MM/dd/yyyy HH:mm:ss',
            // dataType: 'datetime',
            alignment: 'left',
            cellRender: (item) => <a onClick={() => loadMontage(item)}>{formatDateTimeWithMilliseconds(item.value)}</a>
        },
        {
            caption: 'Buy or Sell',
            dataField: 'bors',
            format: null
        },
        {
            caption: 'Exec Quantity',
            dataField: 'executionQuantity',
            format: null,
            format: '#,##0'
        },
        {
            caption: 'Leaves Quantity',
            dataField: 'leaveQuantity',
            format: null,
            format: '#,##0'
        },
        {
            caption: 'Exec Price',
            dataField: 'executionPrice'
        },
        {
            caption: 'Exec Time',
            dataField: 'executionTime',
            // format: 'MM/dd/yyyy HH:mm:ss',
            // dataType: 'datetime',
            alignment: 'left',
            cellRender: (item) => <a onClick={() => loadMontage(item)}>{formatDateTimeWithMilliseconds(item.value)}</a>
        },
        {
            caption: 'Exec Quality',
            dataField: 'executionQuality',
            format: null,
            format: '#0.##0'
        }
    ]

    return (
        <DataGrid
            id='routes-eq-individual'
            className={classNames(styles.grid, {
                [styles.hideGrid]: hideGrid
            })}
            dataSource={data}
            hoverStateEnabled
            columnAutoWidth
            rowAlternationEnabled
            showBorders
            repaintChangesOnly
            >
                {columns.map(col => <Column
                    key={col.caption}
                    caption={col.caption}
                    dataField={col.dataField}
                    dataType={col.dataType}
                    format={col.format}
                    alignment={col.alignment}
                    cellRender={col.cellRender}
                />)}
                <Scrolling mode='infinite' rowRenderingMode='virtual' />
                <Paging enabled={false} />
            </DataGrid>
    );
};

DataRoutesIndividual.propTypes = {
    data: PropTypes.array
};

export default DataRoutesIndividual;