import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import classNames from 'classnames';
import { IoMdDownload } from "react-icons/io";
import { useEqv } from '../../context/Data.context';
import styles from './TabNav.module.scss';

const tabItems = [
    {
        path: 'overview',
        title: 'Overview'
    },
    {
        path: 'routes-overview',
        title: 'Routes Overview'
    },
    {
        path: 'rejects',
        title: 'Rejects'
    }
];

const OVERVIEW_PATH = '/execution-quality/overview';
const ALL_ITEM = 'All Routes';

const TabNav = () => {
    const [dataState, dispatchData, handleLoadData] = useEqv();
    const [activeSubItem, setActiveSubItem] = useState(ALL_ITEM)
    const location = useLocation();
    const pathname = location.pathname;
    const contraList = dataState.appliedFilters.contraList || [];
    const showRoutes = contraList && contraList.length > 0 && pathname === OVERVIEW_PATH;
    const isDataLoaded = dataState.isLoaded;
    const isDownloading = dataState.isDownloading;
    const isBtnActive = isDataLoaded && !isDownloading;

    const handleSubItemClick = item => {
        if (item === activeSubItem) return;

        const routesFilter = {
            contraList: [item]
        };

        setActiveSubItem(item);
        handleLoadData(OVERVIEW_PATH, item === ALL_ITEM ? {} : routesFilter);
    };

    const handleDownload = () => {
        dispatchData({
            type: 'START_DOWNLOAD',
            payload: location.pathname.replace('/execution-quality/', '')
        });
    };

    return <div>
        <div className={styles.tabContainer}>
            <ul className={styles.tabs}>
                {tabItems.map(item => <li key={item.title}>
                    <NavLink
                        key={item.title}
                        to={`execution-quality/${item.path}`}
                        className={({ isActive }) =>
                        isActive ? styles.active : null
                        }
                    >
                        {item.title}
                    </NavLink>
                </li>)}
            </ul>
            <IoMdDownload className={classNames(styles.download, {
                [styles.inactive]: !isDataLoaded,
                [styles.isDownloading]: isDownloading
            })} onClick={isBtnActive ? handleDownload : null} />
        </div>
        {showRoutes && <div className={styles.subMenu}>
            <div className={classNames(styles.menuItem, {
                [styles.active]: activeSubItem === ALL_ITEM
            })} onClick={() => handleSubItemClick(ALL_ITEM)}>
                {ALL_ITEM}
            </div>
            {contraList.map(item => <div className={classNames(styles.menuItem, {
                [styles.active]: activeSubItem === item
            })} onClick={() => handleSubItemClick(item)}>{item}</div>)}
        </div>}
    </div>
};

export default TabNav;