import React from 'react';
import PropTypes from 'prop-types';
import {DropDownButton as DropDownDevEx} from 'devextreme-react/drop-down-button';
import ListItem from './ListItem';

const DropDownButton = ({ items, displayExpr, text, icon, noDataText, setSelected }) => {
  const onItemClick = e => {
    setSelected(e.itemData);
  };

  const buttonDropDownOptions = { width: 230 };

  return (
    <DropDownDevEx
      text={text}
      icon={icon && icon}
      displayExpr={displayExpr && displayExpr}
      dropDownOptions={buttonDropDownOptions}
      focusStateEnabled={false}
      noDataText={noDataText && noDataText}
      items={items}
      itemRender={(item) => <ListItem item={item} displayExpr={displayExpr} />}
      onItemClick={onItemClick}
    />
  );
};

DropDownButton.propTypes = {
  items: PropTypes.array,
  text: PropTypes.string,
  icon: PropTypes.string,
  noDataText: PropTypes.string,
  setSelected: PropTypes.func
};

export default DropDownButton;
