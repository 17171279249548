import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextLink.module.scss';

const TextLink = ({ text, onClick }) => <div className={styles.textLink} onClick={onClick}>{text}</div>;

TextLink.propTypes = {
  text: PropTypes.string,
};

export default TextLink;
