import React, { useEffect, useRef } from 'react';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import 'devextreme/data/odata/store';
import { useEqv } from '../../context/Data.context';
import { formatThreeTenths } from '../../utils/helper';
import DataMatrix from '../DataMatrix';
import Loading from '../Loading/Loading';
import TableHeader from '../TableHeader';
import styles from './Overview.module.scss';

const Overview = () => {
    const overallRef = useRef();
    const marketOrderRef = useRef();
    const marketLimitOrderRef = useRef();
    const [dataState, dispatchData] = useEqv();
    const appliedFilters = dataState.appliedFilters;
    const totals = dataState && dataState.overview && dataState.overview.totals;
    const overallHeader = `Overall EQ = ${totals && formatThreeTenths(totals.overall)}`;
    const marketOrderHeader = `Market Order Type EQ = ${totals && formatThreeTenths(totals.marketOrder)}`;
    const marketLimitOrderHeader = `Marketable Limit Order Type EQ = ${totals && formatThreeTenths(totals.limitedOrder)}`;

    useEffect(() => {
        if (dataState.isLoaded && dataState.overviewDownload === true) exportGrids();
    }, [dataState.overviewDownload]);

    const exportGrids = () => {
        const workbook = new Workbook();
        const sheets = [
            {
                sheet: workbook.addWorksheet('Overall EQ'),
                header: overallHeader
            },
            {
                sheet: workbook.addWorksheet('Market Order Type EQ'),
                header: marketOrderHeader
            },
            {
                sheet: workbook.addWorksheet('Marketable Limit Order Type EQ'),
                header: marketLimitOrderHeader
            }
        ];

        const filename = `Overview-${appliedFilters.fromDate}-${appliedFilters.toDate}.xlsx`;
        sheets.forEach(sheet => {
            sheet.sheet.getRow(1).getCell(1).value = sheet.header;
            sheet.sheet.getRow(1).getCell(1).font = { bold: true, size: 16, underline: 'double' };
        });

        exportDataGrid({
            worksheet: sheets[0].sheet,
            component: overallRef.current.instance,
            topLeftCell: { row: 2, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                setHeaderBackground(gridCell, excelCell);
            },
        }).then(() => exportDataGrid({
            worksheet: sheets[1].sheet,
            component: marketOrderRef.current.instance,
            topLeftCell: { row: 2, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                setHeaderBackground(gridCell, excelCell);
            },
        })).then(() => exportDataGrid({
            worksheet: sheets[2].sheet,
            component: marketLimitOrderRef.current.instance,
            topLeftCell: { row: 2, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                setHeaderBackground(gridCell, excelCell);
            },
        })).then(() => {
            sheets.forEach(sheet => {
                sheet.sheet.getCell('A2').value = 'Notional ⇩ - Exec Price ⇨';
                
                for (let i = 2; i < 9; i++) {
                    const excelCell = sheet.sheet.getRow(i).getCell(1)
                    excelCell.font = { bold: true};
                }
            });

            workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
            });

            dispatchData({
                type: 'DOWNLOAD_COMPLETE',
                payload: 'overview'
            })
        });
    }

   const  setHeaderBackground = (gridCell, excelCell) => {
        if (gridCell.column.name === 'sideHeader' || gridCell.rowType === 'header') {
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' }, bgColor: { argb: 'D3D3D3' } };
        }

        if (gridCell.column.format) {
            if (gridCell.column.format === '0.##0') excelCell.numFmt = '0.##0';
        }
    }

    return <div>
        <Loading
            type='table'
            loaded={dataState.isLoaded}
            error={(dataState.error && dataState.error !== 'canceled') && dataState.error}>
            <div className={styles.spacer}/>
            <div className={styles.dataContainer}>
                <TableHeader text={overallHeader} />
                <DataMatrix data={dataState.overview.overall} orderType='' ref={overallRef} />
            </div>
            <div className={styles.dataContainer}>
                <TableHeader text={marketOrderHeader} />
                <DataMatrix data={dataState.overview.marketOrder} orderType='M' ref={marketOrderRef} />
            </div>
            <div className={styles.dataContainer}>
                <TableHeader text={marketLimitOrderHeader} />
                <DataMatrix data={dataState.overview.limitedOrder} orderType='L' ref={marketLimitOrderRef} />
            </div>
        </Loading>
    </div>
};

export default Overview;