import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DataGrid, { Column, Paging, Scrolling, Selection, MasterDetail } from 'devextreme-react/data-grid';
import IndividualDetail from '../IndividualDetail';
import styles from './DataIndividual.module.scss';

const columns = [
    {
        caption: 'First Exec Price',
        dataField: 'firstPrice'
    },
    {
        caption: 'Route',
        dataField: 'contra',
        format: null
    },
    {
        caption: 'Symbol',
        dataField: 'symbol',
        format: null,
        cellRender: (item) => <a href={`https://www.otcmarkets.com/stock/${item.value}/overview`} target='_blank' rel='noopener noreferrer'>{item.value}</a>
    },
    {
        caption: 'Order ID',
        dataField: 'orderId',
        format: null
    },
    {
        caption: 'Order Time',
        dataField: 'orderTime',
        format: 'MM/dd/yyyy HH:mm:ss.SSS',
        dataType: 'datetime'
    },
    {
        caption: 'Buy or Sell',
        dataField: 'bors',
        format: null
    },
    {
        caption: 'Order Quantity',
        dataField: 'orderQuantity',
        format: '#,##0'
    },
    {
        caption: 'Order Price',
        dataField: 'price'
    },
    {
        caption: 'Trade Count',
        dataField: 'tradeCount',
        format: '#,##0'
    },
    {
        caption: 'Exec Quality',
        dataField: 'executionQuality',
        format: '#0.##0'
    }
];

const handleSelectionChanged = (e) => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
};

const DataIndividual = ({ data, hideGrid }) => {
    return (
        <DataGrid
            id='overall-eq-individual'
            className={classNames(styles.grid, {
                [styles.hideGrid]: hideGrid
            })}
            dataSource={data}
            onSelectionChanged={handleSelectionChanged}
            hoverStateEnabled
            columnAutoWidth
            rowAlternationEnabled
            showBorders
            repaintChangesOnly
            >
                {columns.map(col => <Column
                    key={col.caption}
                    caption={col.caption}
                    dataField={col.dataField}
                    dataType={col.dataType}
                    format={col.format}
                    cellRender={col.cellRender}
                />)}
                <Selection mode='single' />
                <Scrolling mode='infinite' rowRenderingMode='virtual' />
                <Paging enabled={false} />
                <MasterDetail
                    enabled={true}
                    component={IndividualDetail} />
            </DataGrid>
    );
};

DataIndividual.propTypes = {
    data: PropTypes.array,
    hideGrid: PropTypes.bool
};

export default DataIndividual;