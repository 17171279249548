import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEqv } from '../../context/Data.context';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import { formatThreeTenths } from '../../utils/helper';
import { IndividualRoutesOverview } from '../IndividualRoutesOverview';

const DataRoutesOverview = (props, ref) => {
    const [dataState, dispatchData] = useEqv();
    const [showIndividual, setShowIndividual] = useState(false);
    const [individualInfo, setIndividualInfo] = useState(null);
    const data = props.data;
    const orderType = props.orderType;

    const handleCellClick = e => {
        const dataField = e.column.dataField;
        let routesMinMaxAllEq;

        switch(dataField) {
            case 'meanEq':
            case 'stdDevEq':
            case 'weightedMedian':
            case 'interQuartileRange':
                routesMinMaxAllEq = 'ALL_EQ'
                break;
            case 'minEq':
                routesMinMaxAllEq = 'MIN_EQ'
                break;
            case 'maxEq':
                routesMinMaxAllEq = 'MAX_EQ'
                break;
            default:
                routesMinMaxAllEq = 'ALL_EQ'
        };

        const info = {
            routesMinMaxAllEq,
            caption: e.column.caption,
            value: e.value,
            selectedRoute: e.data.contra
        };

        setIndividualInfo(info);
        setShowIndividual(true);
    };

    const handleIndividualClose = () => {
        dispatchData({
            type: 'REMOVE_MONTAGE'
        });
        setShowIndividual(false);
    };

    const columns = [
        {
            caption: 'Route',
            dataField: 'route',
            format: null
        },
        {
            caption: 'Notional Value',
            dataField: 'notional',
            format: '#,#00.00',
            dataType: 'number',
            precision: 2
        },
        {
            caption: 'Trades',
            dataField: 'trades',
            format: '#,##0'
        },
        {
            caption: 'Shares Exec',
            dataField: 'shares',
            format: '#,##0'
        },
        {
            caption: 'Speed',
            dataField: 'speed',
            format: '#0.##0',
            cellRender: (item) => formatThreeTenths(item.value)
        },
        {
            caption: 'EQ Mean',
            dataField: 'meanEq',
            format: '#0.##0',
            cellRender: (item) => <a onClick={() => handleCellClick(item)}>{formatThreeTenths(item.value)}</a>
        },
        {
            caption: 'EQ STD DEV',
            dataField: 'stdDevEq',
            format: '#0.##0',
            cellRender: (item) => <a onClick={() => handleCellClick(item)}>{formatThreeTenths(item.value)}</a>
        },
        {
            caption: 'EQ Median',
            dataField: 'weightedMedian',
            format: '#0.##0',
            cellRender: (item) => <a onClick={() => handleCellClick(item)}>{formatThreeTenths(item.value)}</a>
        },
        {
            caption: 'EQ InterQuartile Range',
            dataField: 'interQuartileRange',
            format: '#0.##0',
            cellRender: (item) => <a onClick={() => handleCellClick(item)}>{formatThreeTenths(item.value)}</a>
        },
        {
            caption: 'Min EQ',
            dataField: 'minEq',
            format: '#0.##0',
            cellRender: (item) => <a onClick={() => handleCellClick(item)}>{formatThreeTenths(item.value)}</a>
        },
        {
            caption: 'Max EQ',
            dataField: 'maxEq',
            format: '#0.##0',
            cellRender: (item) => <a onClick={() => handleCellClick(item)}>{formatThreeTenths(item.value)}</a>
        },
        {
            caption: 'At or Better %',
            dataField: 'atOrBetterPercent',
            format: '#0.##0'
        }
    ];

    return (
        <div>
            <DataGrid
                id='overall-eq'
                dataSource={data}
                ref={ref}
                hoverStateEnabled
                columnAutoWidth
                rowAlternationEnabled
                showBorders
                repaintChangesOnly
                >
                    <Paging enabled={false} />
                    {columns.map(col => <Column
                        key={col.caption}
                        caption={col.caption}
                        dataField={col.dataField}
                        format={col.format}
                        dataType={col.dataType}
                        precision={col.precision}
                        cellRender={col.cellRender}
                    />)}
                </DataGrid>
                {showIndividual && individualInfo && <IndividualRoutesOverview
                    show={showIndividual}
                    info={individualInfo}
                    orderType={orderType}
                    onClose={handleIndividualClose} />}
        </div>
    );
};


// wrapping the DataMatrix component with forwardRef
const forwardedRef = React.forwardRef(DataRoutesOverview);

// Exporting the wrapped component
export default forwardedRef;