import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Scrolling, Paging } from 'devextreme-react/data-grid';

const columns = [
    {
        caption: 'Symbol',
        dataField: 'symbol',
        format: null,
        cellRender: (item) => <a href={`https://www.otcmarkets.com/stock/${item.value}/overview`} target='_blank' rel='noopener noreferrer'>{item.value}</a>
    },
    {
        caption: 'Order ID',
        dataField: 'orderId',
        format: null
    },
    {
        caption: 'Order Time',
        dataField: 'orderTime',
        format: 'MM/dd/yyyy HH:mm:ss.SSS',
        dataType: 'datetime'
    },
    {
        caption: 'Execution Time',
        dataField: 'execTime',
        format: 'MM/dd/yyyy HH:mm:ss.SSS',
        dataType: 'datetime'
    },
    {
        caption: 'Leaves Qty',
        dataField: 'leaveQuantity',
        format: '#,##0'
    },
    {
        caption: 'Order Reject Reason',
        dataField: 'orderRejectReason',
        format: null
    },
    {
        caption: 'Execution Reject Reason',
        dataField: 'execRejectReason',
        format: null
    }
];

const DataRejects = ({ data }) => {
    return (
        <DataGrid
            id='overall-eq'
            dataSource={data}
            hoverStateEnabled
            columnAutoWidth
            rowAlternationEnabled
            showBorders
            repaintChangesOnly
            >
            {columns.map(col => <Column
                key={col.caption}
                caption={col.caption}
                dataField={col.dataField}
                format={col.format}
                dataType={col.dataType}
                cellRender={col.cellRender}
            />)}

            <Scrolling mode='infinite' rowRenderingMode='virtual' />
            <Paging enabled={false} />
        </DataGrid>
    );
};

DataRejects.propTypes = {
    data: PropTypes.array
};

export default DataRejects;