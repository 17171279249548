import React from 'react';
import { Outlet } from 'react-router-dom';
import { DataContextProvider } from '../../context/Data.context';
import Filter from '../Filter';
import Header from '../Header';
import TabNav from '../../components/TabNav';

const Layout = () => {
    return (
        <div className='otc-theme'>
          <DataContextProvider>
            <Header />
            <div className='mbMed'>
              <Filter />
            </div>
            <div className='mbMed'>
              <TabNav />
            </div>
            <Outlet />
          </DataContextProvider>
        </div>
      ); 
};

export default Layout;