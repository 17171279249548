import axios from 'axios';
import api from './api';
import queryString from 'query-string';
import getConfig from '../config';
const { REACT_APP_API_BASE_URL } = getConfig();
let controller;

export const getOverviewMatrix = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    const query = queryString.stringify(params, { skipNull: true, skipEmptyString: true });
    let endpoints = [
        `/api/eqviewer/overview/matrix?${query}`,
        `/api/eqviewer/overview/eq-total?${query}`,
        `/api/eqviewer/overview/matrix?${query}&orderType=M`,
        `/api/eqviewer/overview/eq-total?${query}&orderType=M`,
        `/api/eqviewer/overview/matrix?${query}&orderType=L`,
        `/api/eqviewer/overview/eq-total?${query}&orderType=L`
    ];
    
    return axios.all(endpoints.map((endpoint) => axios.get(endpoint, { baseURL: REACT_APP_API_BASE_URL, signal }))).then(
        axios.spread((overall, overallTotal, marketOrder, marketOrderTotal, limitedOrder, limitedOrderTotal) => {
            return {
                overall: { ...overall.data },
                marketOrder: { ...marketOrder.data },
                limitedOrder: { ...limitedOrder.data },
                totals: {
                    overall: overallTotal.data,
                    marketOrder: marketOrderTotal.data,
                    limitedOrder: limitedOrderTotal.data
                }
            };
        })
    );
};

export const getIndividualOverview = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    const response = await api.request({
        method: 'get',
        params,
        paramsSerializer: params => {
            return queryString.stringify(params, { skipNull: true, skipEmptyString: true })
        },
        signal,
        url: `/api/eqviewer/overview/individual`
    });
    
    return response.data;
};

export const getIndividualDetail = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    const response = await api.request({
        method: 'get',
        params,
        signal,
        url: `/api/eqviewer/overview/individual/detail`
    });
    
    return response.data;
};

export const getRoutesOverview = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    const query = queryString.stringify(params, { skipNull: true, skipEmptyString: true });
    let endpoints = [
        `/api/eqviewer/routes/overview?${query}`,
        `/api/eqviewer/routes/overview/median-inter-quartile?${query}`,
        `/api/eqviewer/routes/overview?${query}&orderType=M`,
        `/api/eqviewer/routes/overview/median-inter-quartile?${query}&orderType=M`,
        `/api/eqviewer/routes/overview?${query}&orderType=L`,
        `/api/eqviewer/routes/overview/median-inter-quartile?${query}&orderType=L`
    ];
    
    return axios.all(endpoints.map((endpoint) => axios.get(endpoint, { baseURL: REACT_APP_API_BASE_URL, signal }))).then(
        axios.spread((overall, overallMIQ, marketOrder, marketOrderMIQ, limitedOrder, limitedOrderMIQ) => {
            const overallData = overall.data.map(item => {
                const miq = overallMIQ.data.find(obj => obj.contra === item.route);
                return { ...miq, ...item };
            });

            const marketOrderData = marketOrder.data.map(item => {
                const miq = marketOrderMIQ.data.find(obj => obj.contra === item.route);
                return { ...miq, ...item };
            });

            const limitedOrderData = limitedOrder.data.map(item => {
                const miq = limitedOrderMIQ.data.find(obj => obj.contra === item.route);
                return { ...miq, ...item };
            });

            return {
                overall: overallData,
                marketOrder: marketOrderData,
                limitedOrder: limitedOrderData
            };
        })
    );
};

export const getIndividualRouteOverview = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    const response = await api.request({
        method: 'get',
        params,
        paramsSerializer: params => {
            return queryString.stringify(params, { skipNull: true, skipEmptyString: true })
        },
        signal,
        url: `/api/eqviewer/routes/overview/individual`
    });
    
    return response.data;
};

export const getRejects = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    const response = await api.request({
        method: 'get',
        params: {
            ...params,
            pageSize: 5000
        },
        paramsSerializer: params => {
            return queryString.stringify(params, { skipNull: true, skipEmptyString: true })
        },
        signal,
        url: `/api/eqviewer/rejects`
    });

    return response.data;
};

export const downloadRejects = async params => {
    const response = await api.request({
        method: 'get',
        params,
        paramsSerializer: params => {
            return queryString.stringify(params, { skipNull: true, skipEmptyString: true })
        },
        responseType: 'arraybuffer',
        url: `/api/eqviewer/rejects/download`
    });

    return response.data;
};
