import axios from 'axios';
import Cookies from 'js-cookie';
import getConfig from '../config';
const { REACT_APP_API_BASE_URL } = getConfig();
const token = Cookies.get('hs_token');

axios.interceptors.request.use(
    config => {
        config.headers['authorization'] = token;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
);

export default {
  request: async (options) => {
    options.baseURL = REACT_APP_API_BASE_URL;

    try {
      let response = await axios(options);
      return response;
    } catch (error) {
      throw error;
    }
  }
};
