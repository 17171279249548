const currentDate = new Date();

const disableDates = args => {
    const dayOfWeek = args.date.getDay();
    const isWeekend = args.view === 'month' && (dayOfWeek === 0 || dayOfWeek === 6);

    return isWeekend;
};

module.exports = [
    {
        title: 'Start Date',
        fieldName: 'fromDate',
        type: 'date',
        max: currentDate,
        disableDates,
        required: true
    },
    {
        title: 'End Date',
        fieldName: 'toDate',
        type: 'date',
        max: currentDate,
        disableDates,
        required: true,
        tooltip: 'endDate',
        toolTipText: 'Copy Start Date',
        copyParam: 'fromDate'
    },
    {
        title: 'Start Time',
        fieldName: 'fromTime',
        type: 'time',
        placeholder: '--:--:--',
        format: 'HH:mm:ss',
        required: true
    },
    {
        title: 'End Time',
        fieldName: 'toTime',
        type: 'time',
        placeholder: '--:--:--',
        format: 'HH:mm:ss',
        required: true,
        tooltip: 'endTime',
        toolTipText: 'Copy Start Time',
        copyParam: 'fromTime'
    },
    {
        title: 'Notional Min',
        fieldName: 'lowerNotional',
        type: 'number',
        placeholder: '0',
        min: 0
    },
    {
        title: 'Notional Max',
        fieldName: 'upperNotional',
        type: 'number',
        placeholder: 'No Limit',
        min: 0
    },
    {
        title: 'EQ Calculation',
        fieldName: 'eqType',
        type: 'select',
        displayExpr: 'Name',
        valueExpr: 'ID',
        options: [
            {
                Name: 'OTC Link EQ',
                ID: 'EQ'
            },
            {
                Name: '605 EQ',
                ID: 'EQ605'
            }
        ]
    },
    {
        title: 'Route',
        fieldName: 'contraList',
        type: 'tagbox',
        searchEnabled: true,
        searchMode: 'contains',
        selectAll: true,
        placeholder: 'All',
        options: [],
        canChage: true
    },
    {
        title: 'Symbol',
        fieldName: 'symbolList',
        type: 'tagbox',
        searchEnabled: true,
        searchMode: 'contains',
        placeholder: 'All',
        dataSource: true,
        options: [],
        canChage: true
    },
    {
        title: 'Market',
        fieldName: 'marketList',
        type: 'tagbox',
        searchEnabled: true,
        searchMode: 'contains',
        selectAll: true,
        placeholder: 'All',
        options: [],
        canChage: true
    },
    {
        title: 'Sub-Market',
        fieldName: 'subMarketList',
        type: 'tagbox',
        searchEnabled: true,
        searchMode: 'contains',
        selectAll: true,
        placeholder: 'All',
        options: ['ASDFADF', 'ASFAFAF', 'ASADF'],
        canChage: true
    },
    {
        title: 'Local Market',
        fieldName: 'countryList',
        type: 'tagbox',
        searchEnabled: true,
        searchMode: 'contains',
        selectAll: true,
        placeholder: 'All',
        options: [],
        canChage: true
    },
    {
        title: 'Security Type',
        fieldName: 'securityTypeList',
        type: 'tagbox',
        searchEnabled: true,
        searchMode: 'contains',
        selectAll: true,
        placeholder: 'All',
        options: [],
        canChage: true
    },
    {
        title: 'Industry',
        fieldName: 'industryList',
        type: 'tagbox',
        displayExpr: 'industryName',
        valueExpr: 'industryName',
        searchEnabled: true,
        searchMode: 'contains',
        placeholder: 'All',
        options: [],
        dataSource: true,
        canChage: true
    },
    {
        title: 'Order ID',
        fieldName: 'orderId',
        type: 'text',
        placeholder: 'Order ID',
    },
    {
        title: 'EQ Client',
        fieldName: 'mmid',
        type: 'select',
        displayExpr: 'mmid',
        valueExpr: 'mmid',
        isSuper: true
    }
];
