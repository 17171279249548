import moment from 'moment-timezone';

export const numberWithCommas = (x) => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

export const formatDateTime = value => moment(value).format('MM/DD/YYYY HH:mm:ss');
export const formatDateTimeWithMilliseconds = value => moment(value).format('MM/DD/YYYY HH:mm:ss.SSS');
export const formatApiDate = value => moment(value).format('YYYY-MM-DD');
export const formatTime = value => moment(value).format('HH:mm:ss.SSS');
export const formatApiTime = value => {
    if (typeof value === 'object') {
        return moment(value).format('HH:mm:ss');
    } else return value;
};

export const formatThreeTenths = (value, placeholder) => {
    value = parseFloat(value);
    if (isNaN(value)) return placeholder || value;
    value = numberWithCommas(value.toFixed(3));
    if (value === '-0.00') value = '0.00';
    return value
};

export const createSymbolListQueryParams = filters => {
    const params = { ...filters };
    const hasSymbols = params.symbolList && params.symbolList.length > 0;
    const hasIndustries = params.industryList && params.industryList.length > 0;
    let symbolQuery = [];
    let selectedIndustryList = [];
    let industrySymbolList = [];

    if (hasIndustries && params.industryListReference) selectedIndustryList = params.industryListReference.filter(obj => params.industryList.includes(obj.industryName)).map(obj => obj.symbols);

    selectedIndustryList.forEach(arr => {
        arr.forEach(item => industrySymbolList.push(item));
    });

    if (hasSymbols && !hasIndustries) symbolQuery = params.symbolList;
    
    if (!hasSymbols && hasIndustries) symbolQuery = industrySymbolList;
    
    if (hasSymbols && hasIndustries) symbolQuery = params.symbolList.filter(symbol => industrySymbolList.find(item => item === symbol));
    
    delete params.industryList;
    delete params.industryListReference;

    return {
        ...params,
        symbolList: symbolQuery
    }
};

const initialOverviewData = [
    {
        key: 'lessThan500',
        notional: 'Less than $500.00'
    },
    {
        key: 'lessThan1000',
        notional: '$500.00-$999.99'
    },
    {
        key: 'lessThan2500',
        notional: '$1000.00-$2,499.99'
    },
    {
        key: 'lessThan5000',
        notional: '$2,500.00-$4,999.99'
    },
    {
        key: 'lessThan10000',
        notional: '$5,000.00-$9,999.99'
    },
    {
        key: 'moreThan10000',
        notional: '$10,000.00 and Up'
    }
];

export const formatOverviewData = data => {
    const formattedData = {
        overall: [],
        marketOrder: [],
        limitedOrder: []
    };

    for (const [topicKey] of Object.entries(data)) {
        formattedData[topicKey] = initialOverviewData.map(object => {
            const stats = data[topicKey][object.key].reduce((obj, item) => ({
                ...obj,
                [item.bucketPrice ? item.bucketPrice.replace(/[^a-zA-Z0-9]/g, '') : '']: item.eq
            }), {});
            return {
                ...object,
                ...stats
            }
        });
    };

    return formattedData;
}

export const parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};