import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { useEqv } from '../../context/Data.context';
import { postSavedSearches, updateSavedSearches } from '../../api/saveSearch';
import { SelectBox, DateBox, NumberBox, Popup, TextBox, TagBox } from 'devextreme-react';
import DataSource from "devextreme/data/data_source";
import Tooltip from 'devextreme-react/tooltip';
import { formatApiTime } from '../../utils/helper';
import { getInputControls } from '../../api/inputControls';
import filterItems from './filters';
import ManageSearch from './ManageSearch';
import SaveSearch from './SaveSearch';
import { Button } from '../Button';
import { DropDownButton } from '../DropDownButton';
import { TextLink } from '../TextLink';
import { Label } from '../Label';
import styles from './Filter.module.scss';

const Filters = () => {
    const [dataState, dispatchData, handleLoadData] = useEqv();
    const [inputControls, setInputControls] = useState({});
    const [saveSearchName, setSaveSearchName] = useState(null);
    const [showPopup, togglePopup] = useState(false);
    const [popupOpen, setPopUpOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState(null);
    const [tooltips, toggleTooltip] = useState({});
    const [savedSearchItems, setSavedSearchItems] = useState(null);
    const [saveError, setSaveError] = useState(null);
    const [isSaveSubmitting, setSaveSubmitting] = useState(null);
    let fitlerFields = filterItems;
    const location = useLocation();
    const errorMsg = dataState.invalidMsg;
    const filterContext = dataState.filters;
    const showMaxNote = dataState.data && dataState.data.maxData;
    const isManagePopup = popupTitle === 'Mange Saved Searches';
    const isSavePopup = popupTitle === 'Save Search';
    const isMaxSaves = savedSearchItems && savedSearchItems.length >= 20;
    const SaveSearchRoadblock = <div style={{ marginBottom: '10px' }}>Unable to save this search. You currently have a maximum 20 saved searches. To manage your saved searches <TextLink text='click here' onClick={() => setPopupTitle('Mange Saved Searches')} /> then try again.</div>;
    const hasSaveSearchItems = savedSearchItems && savedSearchItems.length > 0;
    const settingsItem = {
        id: 'Settings',
        searchName: 'Manage Searches'
    };

    let symbolDataSource;
    let industryDataSource;

    if (dataState && dataState.user && !dataState.user.isSuper) {
        fitlerFields = fitlerFields.filter(item => !item.isSuper);
    }

    useEffect(() => {
        handleSubmit();
    }, [location.pathname]);

    useEffect(() => {
        const savedSearches = dataState.savedSearches;
        
        if (savedSearches && savedSearches.length > 0) setSavedSearchItems(savedSearches);
    }, [dataState.savedSearches]);

    useEffect(() => {
        resetInputControls();
    }, [filterContext.fromDate, filterContext.toDate]);
    
    useEffect(() => {
        loadInputControls(true);
        
        dispatchData({
            type: 'RESET_DYNAMIC_FILTERS'
        });
    }, [filterContext.mmid]);

    const loadInputControls = () => {
        const params = { mmid: filterContext.mmid, fromDate: filterContext.fromDate, toDate: filterContext.toDate };

        getInputControls(params)
            .then(data => {
                dispatchData({
                    type: 'STORE_MARKET_MAKERS',
                    payload: data.mmid
                });

                createInputControls(data);
            })
            .catch(err => console.log('ERR', err));
    };

    const resetInputControls = () => {
        const params = { mmid: filterContext.mmid, fromDate: filterContext.fromDate, toDate: filterContext.toDate };

        getInputControls(params)
            .then(data => {
                createInputControls(data);

                const updateSelections = fitlerFields.filter(item => item.canChage);
                updateSelections.forEach(input => {
                    const fieldName = input.fieldName;
                    const selected = filterContext[fieldName];
                    let updatedCtrl = data[fieldName];

                    if (fieldName === 'industryList') updatedCtrl = updatedCtrl.map(item => item.industryId);

                    if (selected && selected.length > 0) {
                        const updatedSelection = selected.filter(x => updatedCtrl.includes(x));
    
                        dispatchData({
                            type: 'UPDATE_FILTER',
                            payload: updatedSelection,
                            field: fieldName
                        });
                    }
                });
            })
            .catch(err => console.log('ERR', err));
    };

    const createInputControls  = data => {
        dispatchData({
            type: 'STORE_INDUSTRY_LIST',
            payload: data.industryList
        });
        
        symbolDataSource = new DataSource({
            store: data.symbolList,
            paginate: true,
            pageSize: 100
        });

        industryDataSource = new DataSource({
            store: data.industryList,
            paginate: true,
            pageSize: 100
        });
        
        setInputControls({ ...data, symbolList: symbolDataSource, industryList: industryDataSource });
    };

    const handleFieldChange = (e, name) => {
        dispatchData({
            type: 'UPDATE_FILTER',
            payload: e.value,
            field: name
        });
    };
    
    const handleDateChange = (e, name) => {
        if (e.name === 'text') {
            dispatchData({
                type: 'UPDATE_FILTER',
                payload: e.value === '' ? null : moment(e.value).format('YYYY-MM-DD'),
                field: name
            });
        }
    };

    const handleTimeChange = (e, name) => {
        dispatchData({
            type: 'UPDATE_FILTER',
            payload: !e.value ? null : formatApiTime(e.value),
            field: name
        });
    };

    const handleSubmit = () => {
        handleLoadData(location.pathname);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSubmit();
        };
    }

    const handleSave =  () => {
        setPopupTitle('Save Search');
        togglePopup(!showPopup);
    };

    const handleReset = () => dispatchData({
        type: 'RESET_FILTERS'
    });

    const onTagBoxOpened = (e, item) =>{
        if (!item.selectAll) e.component.content().querySelector('.dx-list-select-all').style.display = 'none';
    };

    const renderFieldType = item => {
        const fieldName = item.fieldName;
        const value = filterContext[fieldName];

        switch (item.type) {
            case 'text':
                return <TextBox
                    type='text'
                    value={value}
                    showClearButton
                    placeholder={item.placeholder && item.placeholder}
                    onValueChanged={(e) => handleFieldChange(e, fieldName)} />
            case 'date':
                return  <DateBox
                    type='date'
                    value={value}
                    openOnFieldClick
                    max={item.max}
                    useMaskBehavior
                    displayFormat='MM/dd/yyyy'
                    isValid={item.required && value ? true : false}
                    onOptionChanged={(e) => handleDateChange(e, fieldName)}
                    valueChangeEvent='keyup'
                    disabledDates={item.disableDates} />
            case 'time':
                return <DateBox
                    type='time'
                    placeholder={item.placeholder}
                    showClearButton
                    useMaskBehavior
                    showDropDownButton={false}
                    value={value}
                    isValid={item.required && value ? true : false}
                    onValueChanged={(e) => handleTimeChange(e, fieldName)}
                    displayFormat={item.format} />
            case 'number':
                return <NumberBox
                    placeholder={item.placeholder}
                    showClearButton
                    value={value}
                    format={item.format && item.format}
                    min={item.min && item.min}
                    max={item.max && item.max}
                    onValueChanged={(e) => handleFieldChange(e, fieldName)}/>
            case 'select':
                return <SelectBox
                    displayExpr={item.displayExpr && item.displayExpr}
                    valueExpr={item.valueExpr && item.valueExpr}
                    value={value}
                    items={inputControls[fieldName] || item.options}
                    placeholder={item.placeholder}
                    noDataText='No results found'
                    searchEnabled={item.searchEnabled}
                    searchMode={item.searchEnabled && item.searchMode || 'contains'}
                    onValueChanged={(e) => handleFieldChange(e, fieldName)} />
            case 'tagbox':
                return <TagBox
                    displayExpr={item.displayExpr && item.displayExpr}
                    valueExpr={item.valueExpr && item.valueExpr}
                    value={value}
                    items={!item.dataSource ? inputControls[fieldName] : null}
                    dataSource={item.dataSource && inputControls[fieldName]}
                    placeholder={item.placeholder}
                    noDataText='No results found'
                    showDropDownButton
                    showSelectionControls
                    selectAllMode='allPages'
                    maxDisplayedTags={3}
                    multiline={false}
                    onOpened={(e) => onTagBoxOpened(e, item)}
                    searchEnabled={item.searchEnabled}
                    searchMode={item.searchEnabled && item.searchMode || 'contains'}
                    onValueChanged={(e) => handleFieldChange(e, fieldName)} />
        }
    };

    const handleCopyValue = (to, from) => {
        dispatchData({
            type: 'UPDATE_FILTER',
            payload: filterContext[from],
            field: to
        });
    };    

    const handleSaveSearchSelected = value => {
        if (value && value.id && value.id === 'Settings') {
            setPopupTitle('Mange Saved Searches');
            togglePopup(!showPopup);
        } else {
            dispatchData({
                type: 'SET_SAVED_FILTERS',
                payload: value
            });
        }
    }

    const handlePopupHiding = () => {
        setPopupTitle('');
        setPopUpOpen(false);
        togglePopup(!showPopup);
        setSaveError(false);
    }

    const handleSaveNameFieldChange = value => setSaveSearchName(value);

    const handleSaveSearch = () => {
        if (!saveSearchName) {
            setSaveError('Name is required to save.');
            return;
        }

        setSaveError(false);
        setSaveSubmitting(true);

        const userSavedSearches = dataState.savedSearches;
        const params = dataState.saveSearchParams;
        const filters = { ...filterContext };

        delete filters.toDate;
        delete filters.fromDate;
        delete filters.industryListReference;

        const newSaveObj = {
            ...filters,
            searchName: saveSearchName
        };

        if (!userSavedSearches) {
            const body = {
                savedSearches: [
                    {
                        ...newSaveObj
                    }
                ]
            };

            postSavedSearches(params, body)
                .then(data => {
                    updateSaveList(data);
                    resetSaveSearch();
                })
                .catch(e => {
                    setSaveSubmitting(false);;
                    setSaveError('Error saving your search. Try again.');
                });
        } else {
            const body = {
                savedSearches: [
                    ...savedSearchItems || [],
                    {
                        ...newSaveObj
                    }
                ]
            };

            updateSavedSearches(params, body)
                .then(data => {
                    updateSaveList(data);
                    resetSaveSearch();
                })
                .catch(e => {
                    setSaveSubmitting(false);
                    setSaveError('Error saving your search. Try again.');
                });
        }
    };

    const handleSaveUpdate = list => {
        setSavedSearchItems(list);
        setSaveError(false);
        setSaveSubmitting(true);

        const params = dataState.saveSearchParams;
        const body = {
            savedSearches: [
                ...list
            ]
        };

        updateSavedSearches(params, body)
            .then(data => {
                setSaveSubmitting(false);
                updateSaveList(data);
            })
            .catch(e => {
                setSaveSubmitting(false);
                setSaveError('Error saving your search. Try again.');
            });
    };

    const updateSaveList = data => dispatchData({
        type: 'SET_SAVED_SEARCHES',
        payload: data && data.savedSearches || []
    });

    const resetSaveSearch = () => {
        setSaveSearchName('');
        setSaveError(false);
        setSaveSubmitting(false);
        togglePopup(!showPopup);
    };

    const handleCancelSearch = () => {
        setSaveSearchName('');
        togglePopup(!showPopup);
    };

    const handlePopupShown = () => setPopUpOpen(true);

    return <div className={styles.container}>
        <div className={styles.formContainer}>
            <form className={styles.form} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                {fitlerFields.map(item => <div key={item.title} className={styles.field}>
                    {item.tooltip && <div id={item.tooltip}
                    onMouseEnter={() => toggleTooltip({ [item.tooltip]: true })}
                    onMouseLeave={() => toggleTooltip({ [item.tooltip]: false })}
                    onClick={() => handleCopyValue(item.fieldName, item.copyParam)}>
                        <Label title={item.title} className={styles.labelButton} />
                    </div>}
                    {!item.tooltip && <Label title={item.title} />}

                    {renderFieldType(item)}
                    {item.tooltip &&  <Tooltip
                        target={`#${item.tooltip}`}
                        position={ {my: 'left bottom', at: 'left top' } }
                        visible={tooltips[item.tooltip]}
                        closeOnOutsideClick={false}>
                        {item.toolTipText}
                    </Tooltip>}
                </div>)}
            </form>
            <div className={styles.controls}>
                <Button type='submit' title='Search' size='extraSmall' onClick={handleSubmit} />
                <DropDownButton items={hasSaveSearchItems && [settingsItem, ...savedSearchItems]} displayExpr='searchName' noDataText={'No saved searches'} text='Saved Searches' setSelected={handleSaveSearchSelected} />
                <TextLink text='Save' onClick={handleSave} />
                <TextLink text='Reset All' onClick={handleReset} />
            </div>
            {errorMsg && <div className={styles.error}>
                {errorMsg}
            </div>}
        </div>
        {showMaxNote && <div className={styles.warning}>
            Your current search criteria contains more than 5,000 records. Click the download button below for a full list or refine your search criteria by adding or updating the start time and end time fields.
        </div>}

        <Popup
            visible={showPopup}
            closeOnOutsideClick
            title={popupTitle}
            dragEnabled={false}
            width='400px'
            height='auto'
            onShown={handlePopupShown}
            onHiding={handlePopupHiding}
            >
                {isManagePopup && <ManageSearch dataSource={savedSearchItems} displayExpr='searchName' isSubmitting={isSaveSubmitting} onUpdate={handleSaveUpdate} />}
                {(isSavePopup && isMaxSaves) && SaveSearchRoadblock}
                {(isSavePopup && !isMaxSaves) && <SaveSearch value={saveSearchName} onFieldChange={handleSaveNameFieldChange} isSubmitting={isSaveSubmitting} onSave={handleSaveSearch} initFocus={popupOpen} onCancel={handleCancelSearch} error={saveError} />}
        </Popup>
    </div>
};

export default Filters;