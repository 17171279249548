import React, { useEffect, useRef } from 'react';
import { TextBox } from 'devextreme-react';
import Button from '../Button';
import Label from '../Label';
import TextLink from '../TextLink';
import Loading from '../Loading';
import styles from './Filter.module.scss';

const SaveSearch = ({ value, onFieldChange, onSave, onCancel, isSubmitting, initFocus, error }) => {
    const textRef = useRef();

    useEffect(() => {
        textRef.current.instance.focus();  
    }, [initFocus]);

    return <div>
        <Label title='Name' />
        <TextBox
            ref={textRef}
            type='text'
            value={value}
            showClearButton
            placeholder='Search Name'
            maxLength={50}
            onEnterKey={onSave}
            onValueChanged={(e) => onFieldChange(e.value)} />

        {!isSubmitting && <div className={styles.controls}>
            <Button type='submit' title='Save' size='extraSmall' onClick={onSave} />
            <TextLink text='Cancel' onClick={onCancel} />
        </div>}
        {isSubmitting && <div style={{ marginTop: '20px' }}>
            <Loading />
        </div>}
        {error && <p className={styles.error}>{error}</p>}
    </div>
};

export default SaveSearch;