const getConfig = _ => {
  // Use env vars as default
  if (window.location.host.match(/localhost/)) {
    return {
      REACT_ENV: 'local',
      REACT_APP_API_BASE_URL: 'http://localhost:5001'
    };
  } else if (window.location.host.match(/dev/)) {
    return {
      REACT_ENV: 'dev',
      REACT_APP_API_BASE_URL: 'https://eqviewer.dev.otcmkt.com'
    };
  } else if (window.location.host.match(/test/)) {
    return {
      REACT_ENV: 'test',
      REACT_APP_API_BASE_URL: 'https://eqviewer.test.otcmkt.com'
    };
  } else if (window.location.host.match(/qa/)) {
    return {
      REACT_ENV: 'qa',
      REACT_APP_API_BASE_URL: 'https://eqviewer.test.otcmkt.com'
    };
  } else if (window.location.host.match(/stage/)) {
    return {
      REACT_ENV: 'stage',
      REACT_APP_API_BASE_URL: 'https://eqviewer.stage.otc'
    };
  } else if (window.location.host.match(/.otcmarkets\.com/)) {
    return {
      REACT_ENV: 'prod',
      REACT_APP_API_BASE_URL: "https://eqviewer.otcmarkets.com"
    };
  }
};

export default getConfig;
