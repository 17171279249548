import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Overview from './components/Overview';
import RoutesOverview from './components/RoutesOverview';
import Rejects from './components/Rejects';
import './styles/otc-theme.scss';
class App extends Component {
  render () {
    return <BrowserRouter>
        <Routes>
        <Route path='/' element={<Layout />}>
            <Route path='execution-quality/overview' element={<Overview />} />
            <Route path='execution-quality/routes-overview' element={<RoutesOverview />} />
            <Route path='execution-quality/rejects' element={<Rejects />} />
        </Route>
        </Routes>
    </BrowserRouter>
  }
}

export default App;
