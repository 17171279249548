import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useEqv } from '../../context/Data.context';
import { getIndividualDetail } from '../../api/overview';
import DataIndividualDetail from '../DataIndividualDetail/DataIndividualDetail';
import { Loading } from '../Loading';

const IndividualDetail = ({ data }) => {
    const [dataState] = useEqv();
    const [detail, setDetail] = useState({ records: [], isLoaded: false });
    const filters = dataState.appliedFilters;
    useEffect(() => {
        const params = {
            mmid: data.data.mmid,
            date: data.data.eqDate,
            timeLong: data.data.orderTime,
            orderId: data.data.orderId,
            eqType: filters.eqType
        };

        getIndividualDetail(params)
            .then(data => setDetail({
                records: data,
                isLoaded: true
            }))
            .catch(err => setDetail({
                error: err && err.message || 'Something went wrong, try again.'
            }));
    }, []);

    return <div>
        <Loading
            type='table'
            data={detail.records}
            loaded={detail.isLoaded}
            error={(detail.error && detail.error !== 'canceled') && detail.error}>
            <DataIndividualDetail data={detail.records} />
        </Loading>
    </div>;
};

IndividualDetail.propTypes = {
  orderType: PropTypes.string
};

export default IndividualDetail;
