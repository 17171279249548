import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const Button = props => (
  <div className={classNames({
    [styles.button]: true,
    [styles.inactive]: props.inactive,
    [styles.fullWidth]: props.fullWidth
  })} onClick={props.onClick}>
    <button
      id={props.id}
      type={props.type || 'button'}
      disabled={props.inactive || false}
      className={classNames(props.className, styles[props.buttonType], styles[props.size], styles[props.color])}>
      {props.title || props.children}
    </button>
  </div>
);

Button.propTypes = {
  /** ID for button */
  id: PropTypes.string,
  /** Class name of the input, for custom designs */
  className: PropTypes.string,
  /** On click method */
  onClick: PropTypes.func,
  /** Button title */
  title: PropTypes.string,
  /** Button state: active by default */
  inactive: PropTypes.bool,
  /** Button content */
  children: PropTypes.node,
  /** Button type */
  type: PropTypes.string,
  /** Button size */
  size: PropTypes.oneOf(['extraSmall', 'small', 'normal']),
  /** Button to be 100% width */
  fullWidth: PropTypes.bool,
  /** buttonType renders different style of button */
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
  /** buttonColor renders different style of button */
  color: PropTypes.oneOf(['green', 'pink', 'orange', 'gray', 'red'])
};

Button.defaultProps = {
  buttonType: 'primary',
  color: 'green',
  fullWidth: false,
  size: 'normal'
};

export default Button;
