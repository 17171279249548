import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';

const bidColumns = [
    {
        caption: 'MPID',
        dataField: 'bidMpid',
        format: null
    },
    {
        caption: 'Bid Price',
        dataField: 'bidPrice',
        format: '#0.##0'
    },
    {
        caption: 'Size',
        dataField: 'bidSize',
        format: '#,##0'
    },
    {
        caption: 'Date/Time',
        dataField: 'bidTime',
        format: 'MM/dd/yyyy HH:mm:ss.SSS',
        dataType: 'datetime'
    },
];

const askColumns = [
    {
        caption: 'MPID',
        dataField: 'askMpid',
        format: null
    },
    {
        caption: 'Ask Price',
        dataField: 'askPrice',
        format: '#0.##0'
    },
    {
        caption: 'Size',
        dataField: 'askSize',
        format: '#,##0'
    },
    {
        caption: 'Date/Time',
        dataField: 'askTime',
        format: 'MM/dd/yyyy HH:mm:ss.SSS',
        dataType: 'datetime'
    },
];

const MontageGrid = ({ type, data }) => {
    const columns = type === 'bid' ? bidColumns : askColumns;

    return <DataGrid
        id='montage'
        dataSource={data}
        hoverStateEnabled
        columnAutoWidth
        rowAlternationEnabled
        showBorders
        repaintChangesOnly
        >
        {columns.map(col => <Column
            key={col.caption}
            caption={col.caption}
            dataField={col.dataField}
            dataType={col.dataType}
            format={col.format}
            alignment={col.alignment}
            cellRender={col.cellRender}
        />)}
        <Paging enabled={false} />
    </DataGrid>
};

MontageGrid.propTypes = {
    title: PropTypes.string
};

export default MontageGrid;
