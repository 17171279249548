import React, { useEffect, useRef } from 'react';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import 'devextreme/data/odata/store';
import { useEqv } from '../../context/Data.context';
import DataRoutesOverview from '../DataRoutesOverview';
import Loading from '../Loading/Loading';
import TableHeader from '../TableHeader';
import styles from './RoutesOverview.module.scss';

const RoutesOverview = () => {
    const overallRef = useRef();
    const marketOrderRef = useRef();
    const marketLimitOrderRef = useRef();
    const [dataState, dispatchData] = useEqv();
    const appliedFilters = dataState.appliedFilters;
    const downloadParam = 'routes-overviewDownload';

    useEffect(() => {
        if (dataState.isLoaded && dataState[downloadParam] === true) exportGrids();
    }, [dataState[downloadParam]]);

    const exportGrids = () => {
        const workbook = new Workbook();
        const sheets = [
            {
                sheet: workbook.addWorksheet('Overall EQ')
            },
            {
                sheet: workbook.addWorksheet('Market Order Type EQ')
            },
            {
                sheet: workbook.addWorksheet('Marketable Limit Order Type EQ')
            }
        ];

        const filename = `Routes-Overview-${appliedFilters.fromDate}-${appliedFilters.toDate}.xlsx`
        sheets.forEach(sheet => {
            sheet.sheet.getRow(1).getCell(1).value = sheet.sheet.name;
            sheet.sheet.getRow(1).getCell(1).font = { bold: true, size: 16, underline: 'double' };

            // sheet.sheet.getColumn(4).numFmt = '0,000';
            // sheet.sheet.getColumn(5).numFmt = '0.000';
            // sheet.sheet.getColumn(6).numFmt = '0.000';
            // sheet.sheet.getColumn(7).numFmt = '0.000';
            // sheet.sheet.getColumn(8).numFmt = '0.000';
            // sheet.sheet.getColumn(9).numFmt = '0.000';
            // sheet.sheet.getColumn(10).numFmt = '0.000';
            // sheet.sheet.getColumn(11).numFmt = '0.000';
            // sheet.sheet.getColumn(12).numFmt = '0.000';
        });

        exportDataGrid({
            worksheet: sheets[0].sheet,
            component: overallRef.current.instance,
            topLeftCell: { row: 2, column: 1 },
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                setHeaderBackground(gridCell, excelCell);
            },
        }).then(() => exportDataGrid({
            worksheet: sheets[1].sheet,
            component: marketOrderRef.current.instance,
            topLeftCell: { row: 2, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                setHeaderBackground(gridCell, excelCell);
            },
        })).then(() => exportDataGrid({
            worksheet: sheets[2].sheet,
            component: marketLimitOrderRef.current.instance,
            topLeftCell: { row: 2, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                setHeaderBackground(gridCell, excelCell);
            },
        })).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
            });

            dispatchData({
                type: 'DOWNLOAD_COMPLETE',
                payload: 'routes-overview'
            })
        });
    }

    const  setHeaderBackground = (gridCell, excelCell) => {
        if (gridCell.column.name === 'sideHeader' || gridCell.rowType === 'header') {
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' }, bgColor: { argb: 'D3D3D3' } };
        }

        if (gridCell.column.format) {
            if (gridCell.column.format === '#,#00.00') excelCell.numFmt = '#,#00.00';
            if (gridCell.column.format === '#0.##0') excelCell.numFmt = '#0.##0';
            if (gridCell.column.format === '#,##0') excelCell.numFmt = '#,##0';
        }
    }

    return <div>
        <Loading
            type='table'
            loaded={dataState.isLoaded}
            error={(dataState.error && dataState.error !== 'canceled') && dataState.error}>
            <div className={styles.dataContainer}>
                <TableHeader text='Overall EQ' />
                <DataRoutesOverview ref={overallRef} data={dataState.routesOverview.overall} orderType='' />
            </div>
            <div className={styles.dataContainer}>
                <TableHeader text='Market Order Type EQ' />
                <DataRoutesOverview ref={marketOrderRef} data={dataState.routesOverview.marketOrder} orderType='M' />
            </div>
            <div className={styles.dataContainer}>
                <TableHeader text='Marketable Limit Order Type EQ' />
                <DataRoutesOverview ref={marketLimitOrderRef} data={dataState.routesOverview.limitedOrder} orderType='L' />
            </div>
        </Loading>
    </div>
};

export default RoutesOverview;