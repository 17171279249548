import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Label.module.scss';

const Label = ({ className, title, isInline }) => {
  return <label className={classNames(className, styles.label, {
    [styles.inline]: isInline
  })}>
    {title}
  </label>;
};

Label.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  isInline: PropTypes.bool
};

export default Label;
