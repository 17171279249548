import React, { useEffect, useState } from 'react';
import { downloadRejects } from '../../api/overview';
import fileDownload from 'js-file-download';
import { createSymbolListQueryParams } from '../../utils/helper';
import { useEqv } from '../../context/Data.context';
import DataRejects from '../DataRejects';
import Loading from '../Loading/Loading';
import styles from './Rejects.module.scss';

const Rejects = () => {
    const [dataState, dispatchData] = useEqv();
    const appliedFilters = dataState.appliedFilters;
    const [downloadError, setDownloadError] = useState(null);

    useEffect(() => {
        if (dataState.isLoaded && dataState.rejectsDownload === true) handleDownload();
    }, [dataState.rejectsDownload]);

    const handleDownload = () => {
        const filename = `Rejects-${appliedFilters.fromDate}-${appliedFilters.toDate}.xlsx`;
        setDownloadError(null)
        const params = {
            ...createSymbolListQueryParams(appliedFilters)
          };
        downloadRejects(params)
            .then(data => {
                fileDownload(data, filename);
                dispatchData({
                    type: 'DOWNLOAD_COMPLETE',
                    payload: 'rejects'
                })
            })
            .catch(err => {
                dispatchData({
                    type: 'DOWNLOAD_COMPLETE',
                    payload: 'rejects'
                })
                setDownloadError(err && err.message || 'Download failed. Try again.')
            });
    };

    return <div>
        {downloadError && <p className={styles.error}>
            {downloadError}
        </p>}
        <Loading
            type='table'
            loaded={dataState.isLoaded}
            error={(dataState.error && dataState.error !== 'canceled') && dataState.error}>
            <DataRejects data={dataState.rejects.records} />
        </Loading>
    </div>
};

export default Rejects;