import React from 'react';
import { useEqv } from '../../context/Data.context';
import styles from './Header.module.scss';

const s3URL = 'https://s3.amazonaws.com/assets.otcmarkets.com/eqviewer/';

const Header = () => {
  const [dataState, dispatchData] = useEqv();
  const appliedFilters = dataState.appliedFilters;
  const marketMakers = dataState.marketMakers;
  const marketMaker = marketMakers.find(mm => mm.mmid === appliedFilters.mmid);

  return (
    <header className={styles.container}>
      <h1 className={styles.title}>
        Execution Quality Dashboard
      </h1>
      {marketMaker && <div>
        <span className={styles.mmName}>{marketMaker.name}</span>
        <img className={styles.mmLogo} src={`${s3URL}${marketMaker.mmid}.jpg`} alt={marketMaker.name} />
      </div>}
    </header>
  );
};

export default Header;