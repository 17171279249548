import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEqv } from '../../context/Data.context';
import { FaLongArrowAltDown, FaLongArrowAltRight } from 'react-icons/fa';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { IndividualOverview } from '../IndividualOverview';
import styles from './DataMatrix.module.scss';

const DataMatrix = (props, ref) => {
    const [dataState, dispatchData] = useEqv();
    const [showIndividual, setShowIndividual] = useState(false);
    const [individualInfo, setIndividualInfo] = useState(null);
    const data = props.data;
    const orderType = props.orderType;

    const renderHeaderLegend = () => {
        return <div className={styles.headerLegend}>
            <span>
            Notional
            </span>
            <FaLongArrowAltDown />
            <span className={styles.divider}>
            |
            </span>
            <span>
            Exec Price
            </span>
            <FaLongArrowAltRight className={styles.execPriceArrow} />
        </div>;
    }

    const handleCellClick = e => {
        if (e.rowType !== 'header' && e.column.name !== 'sideHeader') {
            const info = {
                notional: e.data.notional,
                executionPrice: e.column.caption,
                price: e.text
            }
            setIndividualInfo(info);
            setShowIndividual(true);
        }
    };

    const handleIndividualClose = () => {
        dispatchData({
            type: 'REMOVE_MONTAGE'
        });
        setShowIndividual(false);
    };
    

    const  renderSideHeader = item => {
    return <div className={styles.sideHeader}>
        {item.value}
    </div>;
    }

    return (
        <div>
            <DataGrid
                id='overall-eq'
                dataSource={data}
                hoverStateEnabled
                columnAutoWidth
                rowAlternationEnabled
                showBorders
                repaintChangesOnly
                ref={ref}
                onCellClick={handleCellClick}
                >
                <Column
                    cssClass='legend'
                    dataField='notional'
                    name='sideHeader'
                    minWidth={100}
                    headerCellRender={renderHeaderLegend}
                    cellRender={renderSideHeader}
                    allowSorting={false}
                    />
                <Column
                    caption='Less than $0.0999'
                    dataField='Lessthan00999'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$0.1000-$0.1999'
                    dataField='0100001999'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$0.2000-$0.5099'
                    dataField='0200005099'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$0.5100-$0.9999'
                    dataField='0510009999'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$1.00-$5.00'
                    dataField='100500'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$5.01-$20.00'
                    dataField='5012000'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$20.01-$50.00'
                    dataField='20015000'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$50.01-$125.00'
                    dataField='500112500'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$125.01-$1,000.00'
                    dataField='12501100000'
                    format='#0.##0'
                    allowSorting={false}
                />
                <Column
                    caption='$1,000.01 and Up'
                    dataField='100001andUp'
                    format='#0.##0'
                    allowSorting={false}
                />
            </DataGrid>
            {showIndividual && individualInfo && <IndividualOverview
                show={showIndividual}
                info={individualInfo}
                orderType={orderType}
                onClose={handleIndividualClose} />}
        </div>

    );
};
// wrapping the DataMatrix component with forwardRef
const forwardedRef = React.forwardRef(DataMatrix);

// Exporting the wrapped component
export default forwardedRef;