import React from 'react';
import List, { ItemDragging } from 'devextreme-react/list';

const ManageSearch = ({ dataSource, displayExpr, onUpdate }) => {
    const list = [...dataSource]
    const onDragStart = e => {
        e.itemData = dataSource[e.fromIndex];
    }

    const onRemove = e => {
        onUpdate(list);
    }
    
    const onReorder = e => {
        const list = dataSource;
        list.splice(e.fromIndex, 1);
        list.splice(e.toIndex, 0, e.itemData);
        onUpdate([...list]);
    }

    return <div>
        <List
            dataSource={list}
            keyExpr={displayExpr}
            displayExpr={displayExpr}
            allowItemDeleting
            itemDeleteMode='static'
            pageLoadMode='scrollBottom'
            onItemDeleted={onRemove}
            repaintChangesOnly={true}>
            <ItemDragging
                allowReordering={true}
                onDragStart={onDragStart}
                onReorder={onReorder}>
            </ItemDragging>
        </List>
    </div>

};

export default ManageSearch;