import api from './api';

export const getMontage = async params => {
    const response = await api.request({
        method: 'get',
        params,
        url: `/api/market-data/montage`
    });
    
    return response.data;
};