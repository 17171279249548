import api from './api';

export const getSavedSearches = async params => {
    const response = await api.request({
        method: 'get',
        url: `/api/filter`,
        params
    });
    
    return response.data;
};

export const postSavedSearches = async (params, data) => {
    const response = await api.request({
        method: 'post',
        url: `/api/filter`,
        params,
        data
    });
    
    return response.data;
};

export const updateSavedSearches = async (params, data) => {
    const response = await api.request({
        method: 'put',
        url: `/api/filter`,
        params,
        data
    });
    
    return response.data;
};