import React from 'react';
import PropTypes from 'prop-types';
import { useEqv } from '../../context/Data.context';
import { Loading } from '../Loading';
import { MontageGrid } from '../MontageGrid';
import { TableHeader } from '../TableHeader';
import styles from './Montage.module.scss';

const Montage = ({ data }) => {
    const [dataState] = useEqv();
    const montageData = dataState.montageData;

    return <div>
        {montageData.isLoading && <div className={styles.header}>
            Building Montage
        </div>}
        <Loading
            type='table'
            data={montageData.records}
            loaded={montageData.isLoaded}
            error={montageData.error}>
                <div className={styles.container}>
                    <div>
                        <TableHeader text='Bid' />
                        <MontageGrid type='bid' data={montageData.records} />
                    </div>
                    <div>
                        <TableHeader text='Ask' />
                        <MontageGrid type='ask' data={montageData.records} />
                    </div>
                </div>
        </Loading>
    </div>;
};

Montage.propTypes = {
    data: PropTypes.array
};

export default Montage;
