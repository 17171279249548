import React from 'react';
import { IoMdSettings } from "react-icons/io";
import styles from './ListItem.module.scss';

const ListItem = ({ item, displayExpr }) => {
  if (item.id && item.id === 'Settings') return <div className={styles.settings}>
    <IoMdSettings className={styles.icon} />
    <span className={styles.settingsText}>{item[displayExpr]}</span>
  </div>;

  return <div className={styles.container}>
    {item[displayExpr]}
  </div>
}

export default ListItem;
