import React, { useEffect, useReducer, createContext } from 'react';
import { getOverviewMatrix, getRoutesOverview, getRejects } from '../api/overview';
import { getSavedSearches } from '../api/saveSearch';
import { getMontage } from '../api/marketData';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { formatDateTime, formatOverviewData, formatApiDate, formatTime, parseJwt, createSymbolListQueryParams } from '../utils/helper';
export const DataContext = createContext();

const token = Cookies.get('hs_token');
const parseToken = token ? parseJwt(token) : null;
const user = parseToken && parseToken.data;
const saveSearchParams = {
  userId: user.traderTrackID,
  application: 'history-server',
  searchType: 'eqv'
};

const defaultFilters = {
  mmid: user && user.marketMakerId,
  fromDate: moment().startOf('month').format(`YYYY-MM-01`),
  toDate: moment().format('YYYY-MM-DD'),
  fromTime: '00:00:00',
  toTime: '23:00:00',
  contraList: null,
  symbolList: [],
  industryList: [],
  industryListReference: [],
  securityTypeList: null,
  countryList: null,
  marketList: null,
  subMarketList: null,
  lowerNotional: 0,
  upperNotional: null,
  orderId: null,
  eqType: 'EQ'
};

const initialState = {
  isLoaded: false,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
  invalidMsg: null,
  data: {},
  overview: {},
  routesOverview: {},
  rejects: {},
  user: user,
  montageData: {
    isLoading: false,
    isLoaded: false,
    records: [],
    error: null
  },
  error: null,
  marketMakers: [],
  isDownloading: false,
  popupTitle: '',
  savedSearches: false,
  savedSearchError: false,
  saveSearchParams
};

const reducer = (state, action) => {
  const payload = action.payload;

  switch (action.type) {
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: {
          ...defaultFilters,
          mmid: state.filters.mmid,
          industryListReference: state.filters.industryListReference
        },
      };
    case 'SET_SAVED_FILTERS':
      return {
        ...state,
        filters: {
          ...payload,
          mmid: state.filters.mmid,
          fromDate: state.filters.fromDate,
          toDate: state.filters.toDate,
          industryListReference: state.filters.industryListReference
        },
      };
    case 'RESET_DYNAMIC_FILTERS':
      return {
        ...state,
        filters: {
          ...defaultFilters,
          mmid: state.filters.mmid,
          fromDate: state.filters.fromDate,
          toDate: state.filters.toDate,
          fromTime: state.filters.fromTime,
          toTime: state.filters.toTime,
          lowerNotional: state.filters.lowerNotional,
          upperNotional: state.filters.upperNotional,
          eqType: state.filters.eqType
        },
      };
    case 'SET_OVERVIEW_DATA':
      const formattedData = formatOverviewData({ overall: payload.overall, marketOrder: payload.marketOrder, limitedOrder: payload.limitedOrder});

      return {
        ...state,
        isLoaded: true,
        error: null,
        routesOverview: {},
        rejects: {},
        overview: {
          ...formattedData,
          totals: payload.totals,
          maxData: action.maxData
        }
      };
    case 'SET_ROUTES_OVERVIEW_DATA':
      return {
        ...state,
        isLoaded: true,
        error: null,
        overview: {},
        rejects: {},
        routesOverview: {
          ...payload,
          totals: payload.totals,
          maxData: action.maxData
        }
      };
    case 'SET_REJECTS_DATA':
      return {
        ...state,
        isLoaded: true,
        error: null,
        overview: {},
        routesOverview: {},
        rejects: {
          ...payload,
          totals: payload.totals,
          maxData: action.maxData
        }
      };
    case 'RELOAD_DATA':
      return {
        ...state,
        isLoaded: false,
        appliedFilters: state.filters,
        invalidMsg: null,
        error: null,
        data: {
          totals: null,
          maxData: false
        }
      };
    case 'SET_DATA_ERROR':
      return {
        ...state,
        error: payload
      }
    case 'SEARCH_INVALID':
      return {
        ...state,
        invalidMsg: payload
      }
    case 'LOAD_MONTAGE':
      return {
        ...state,
        popupTitle: action.payload,
        montageData: {
          ...state.montageData,
          isLoading: true
        }
      };
    case 'SET_MONTAGE':
      return {
        ...state,
        montageData: {
          ...state.montageData,
          isLoading: false,
          isLoaded: true,
          records: action.payload
        }
      };
    case 'MONTAGE_ERROR':
        return {
          ...state,
          montageData: {
            ...state.montageData,
            error: payload
          }
        };
    case 'REMOVE_MONTAGE':
        return {
          ...state,
          popupTitle: null,
          montageData: {
            ...state.montageData,
            isLoaded: false,
            records: []
          }
        };
    case 'SET_TYPES_ERROR':
      return {
        ...state,
        error: <>Technical error. {errors.TRY_AGAIN}</>
      }
    case 'UPDATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: action.payload
        }
      };
    case 'START_DOWNLOAD':
      return {
        ...state,
        isDownloading: true,
        [`${payload}Download`]: true
      }
    case 'DOWNLOAD_COMPLETE':
      return {
        ...state,
        isDownloading: false,
        [`${payload}Download`]: null
      }
    case 'STORE_MARKET_MAKERS':
        return {
          ...state,
          marketMakers: payload
        }
      case 'STORE_INDUSTRY_LIST':
          return {
            ...state,
            filters: {
              ...state.filters,
              industryListReference: payload
            }
          }
    case 'SET_SAVED_SEARCHES':
      return {
        ...state,
        savedSearches: payload
      }
    case 'SAVED_SEARCHES_ERROR':
        return {
          ...state,
          savedSearches: false,
          savedSearchError: true
        }
    default:
      throw new Error();
  }
};

export const DataContextProvider = props => {
  const [dataState, dispatchData] = useReducer(reducer, initialState);
  const filters = dataState.filters;

  useEffect(() => {
    const params = {
      userId: dataState.user.traderTrackID,
      application: 'history-server',
      searchType: 'eqv'
    };

    getSavedSearches(saveSearchParams)
      .then(data => dispatchData({
        type: 'SET_SAVED_SEARCHES',
        payload: data && data.savedSearches || []
      }))
      .catch(err => {
        dispatchData({
          type: 'SAVED_SEARCHES_ERROR'
        })
      })
  }, []);

  const loadMatrix = (path, customFilters) => {
    const params = {
      ...createSymbolListQueryParams(filters),
      ...customFilters
    };

    getOverviewMatrix(params)
        .then(data => {
            dispatchData({
                type: 'SET_OVERVIEW_DATA',
                path,
                payload: data
            })
        })
        .catch(err => dispatchData({
            type: 'SET_DATA_ERROR',
            path,
            payload: err && err.message || 'Something went wrong, try again.'
        }));
  };
  
  const loadRoutesOverview = path => {
    const params = {
      ...createSymbolListQueryParams(filters)
    };

    getRoutesOverview(params)
        .then(data => dispatchData({
            type: 'SET_ROUTES_OVERVIEW_DATA',
            path,
            payload: data
        }))
        .catch(err => dispatchData({
          type: 'SET_DATA_ERROR',
          path,
          payload: err && err.message || 'Something went wrong, try again.'
      }));
  };
  
  const loadRejects = path => {
    const params = {
      ...createSymbolListQueryParams(filters)
    };

    getRejects(params)
        .then(data => dispatchData({
            type: 'SET_REJECTS_DATA',
            path,
            payload: data,
            maxData: data && data.totalRecords && data.totalRecords > 5000 ? true : false
        }))
        .catch(err => dispatchData({
          type: 'SET_DATA_ERROR',
          path,
          payload: err && err.message || 'Something went wrong, try again.'
      }));
  };
  
  const loadMontage = rowItem => {
    const data = rowItem.data;
    const buySell = data.bors === 'B' ? 'BUY' : 'SELL';
    const title = `${formatDateTime(rowItem.value)} : ${buySell} ${data.symbol} ${data.orderQuantity} at ${data.executionPrice} .EQ = ${data.executionQuality}`;
    dispatchData({
      type: 'LOAD_MONTAGE',
      payload: title
    });

    const itemData = rowItem.data;
      const params = {
          secId: itemData.secId,
          date: formatApiDate(rowItem.value),
          time: formatTime(rowItem.value)
      };

      getMontage(params)
          .then(data => dispatchData({
            type: 'SET_MONTAGE',
            payload: data
          }))
          .catch(err => dispatchData({
            type: 'MONTAGE_ERROR',
            path,
            payload: err && err.message || 'Something went wrong, try again.'
        }));
  };

  const isValidQuery = () => {
    const current = moment();
    const fromDate = moment(filters.fromDate);
    const toDate = moment(filters.toDate);
    const fromTime = moment(`01/01/2022 ${filters.fromTime}`);
    const toTime = moment(`01/01/2022 ${filters.toTime}`);
    const isBeforeDate = toDate.isBefore(fromDate);
    const isBeforeTime = toTime.isBefore(fromTime);
    const notionalMin = filters.lowerNotional;
    const notionalMax = filters.upperNotional;

    if (!filters.fromDate) {
      dispatchData({
        type: 'SEARCH_INVALID',
        payload: 'Start date required.'
      });
      return false;
    }

    if (!filters.toDate) {
      dispatchData({
        type: 'SEARCH_INVALID',
        payload: 'End date required.'
      });
      return false;
    }

    if (!filters.fromTime) {
      dispatchData({
        type: 'SEARCH_INVALID',
        payload: 'Start time required.'
      });
      return false;
    }

    if (!filters.toTime) {
      dispatchData({
        type: 'SEARCH_INVALID',
        payload: 'End time required.'
      });
      return false;
    }

    if (fromDate.isAfter(current)) {
        dispatchData({
          type: 'SEARCH_INVALID',
          payload: 'Start date can not be greater than today.'
        });
        return false;
    }

    if (toDate.isAfter(current)) {
        dispatchData({
          type: 'SEARCH_INVALID',
          payload: 'End date can not be greater than today.'
        });
        return false;
    }

    if (isBeforeDate === true) {
        dispatchData({
          type: 'SEARCH_INVALID',
          payload: 'Start Date must be prior than End Date. Please adjust your date range.'
        });
        return false;
    }

    if (isBeforeTime === true) {
        dispatchData({
          type: 'SEARCH_INVALID',
          payload: 'Start Time must be prior than End Time. Please adjust your time range.'
        });
        return false;
    }

    if (notionalMax && notionalMin > notionalMax) {
      dispatchData({
        type: 'SEARCH_INVALID',
        payload: 'Notional Min must be lower than Notional Max.'
      });
      return false;
    }

    return true;
  };

  const handleLoadData = (api, customFilters) => {
    if (!isValidQuery()) return;

    dispatchData({
      type: 'RELOAD_DATA'
    });
  
    switch (api) {
        case '/execution-quality/overview':
            loadMatrix(api, customFilters);
            break;
        case '/execution-quality/routes-overview':
            loadRoutesOverview(api);
            break;
        case '/execution-quality/rejects':
            loadRejects(api);
            break;
    }
  };

  return (
    <DataContext.Provider
      value={[dataState, dispatchData, handleLoadData, loadMontage]}>
      {props.children}
    </DataContext.Provider>
  );
};

export const useEqv = () => {
  return React.useContext(DataContext) ;
};
