import axios from 'axios';
import queryString from 'query-string';
import getConfig from '../config';
const { REACT_APP_API_BASE_URL } = getConfig();
let controller;

export const getInputControls = async params => {
    if (controller) controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    let endpoints = [
        `/api/eqviewer/input-controls/symbol-list`,
        `/api/eqviewer/input-controls/sub-market-list`,
        `/api/eqviewer/input-controls/security-type-list`,
        `/api/eqviewer/input-controls/route-list`,
        `/api/eqviewer/input-controls/mmid`,
        `/api/eqviewer/input-controls/market-list`,
        `/api/eqviewer/input-controls/local-market-list`,
        `/api/eqviewer/input-controls/industry-list`,
    ];
    
    return axios.all(endpoints.map((endpoint) => axios(endpoint, {
        baseURL: REACT_APP_API_BASE_URL,
        method: 'get',
        url: endpoint,
        params,
        signal,
        paramsSerializer: params => {
            return queryString.stringify(params, { skipNull: true, skipEmptyString: true })
        },
    }))).then(
        axios.spread((symbolList, subMarketList, secTypeList, routeList, mmid, marketList, localMktList, industryList) => {
            return {
                symbolList: symbolList.data,
                subMarketList: subMarketList.data,
                securityTypeList: secTypeList.data,
                contraList: routeList.data,
                mmid: mmid.data,
                marketList: marketList.data,
                countryList: localMktList.data,
                industryList: industryList.data
            };
        })
    );
};